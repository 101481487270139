<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span class="primary--text">{{$t('caption.addPoliceCase')}}</span>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn text fab dark large color="primary" @click="$router.push('/police-cases')">
          <v-icon>{{icons.mdiFormatListBulleted}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense :label="$t('case.reportNo')" v-model="policeCase.caseNo" hide-details :rules="rule.requiredField()">

              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense :label="$t('case.receivingDateFromPolice')" v-model="policeCase.caseRegisDate" hide-details type="date" :rules="rule.requiredField()">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn small rounded outlined class="mr-5 primary--text" @click="openPartyListDialog">
                <v-icon>{{icons.mdiAttachment}}</v-icon>ភ្ជាប់អ្នកពាក់ព័ន្ធ</v-btn>

              <v-dialog v-model="partyListDialog">
                <v-card flat class="right-side-dialog">
                  <v-toolbar flat>
                    <v-card-title class="headline">
                      <span class="primary--text">{{$t('case.addRelatedPerson')}}</span>
                    </v-card-title>
                    <v-btn class="btn primary--text" @click="addParty($event)" outlined small rounded>
                      <v-icon small>{{icons.mdiPlus}}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text fab dark large color="primary" @click="partyListDialog=false">
                      <v-icon>{{icons.mdiClose}}</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-card-text class="mt-5">
                    <div v-for="(item,index) in partyList" :key="item.id">
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="item.nameKh" outlined hide-details :label="$t('case.plaintiffName')" dense>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" class="d-none d-sm-flex justify-center position-relative">
                          <v-radio-group row v-model="item.gender" class="mt-0 ml-2">
                            <v-radio :label="$t('text.male')" :value=0></v-radio>
                            <v-radio :label="$t('text.female')" :value=1></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field v-model="item.age" outlined hide-details :label="$t('text.age')" dense>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-select v-model="item.partyRole" :items="partyRoles" label="ប្រភេទភាគី" return-object item-text="roleNameKh" dense hide-details outlined>
                            <template v-slot:selection="data">
                              {{data.item.roleNameKh}}
                            </template>
                            <template v-slot:item="data">
                              {{data.item.roleNameKh}}
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-radio-group row v-model="item.showup" class="mt-0 ml-2">
                            <v-radio label="មានខ្លួន" :value="true"></v-radio>
                            <v-radio label="អត់ខ្លួន" :value="false"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" md="1">
                          <v-icon class="mr-5" large color="primary" @click="removeParty(index)">{{icons.mdiDelete}}</v-icon>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <!--
            <v-col cols="12" md="3">
              <v-radio-group row v-model="policeCase.isPresence" @change="changedPresence" class="mt-0 ml-2">
                <v-radio label="មានខ្លួន" :value="true"></v-radio>
                <v-radio label="អត់ខ្លួន" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            -->
          </v-row>
          <!--
          <v-row v-if="policeCase.isPresence == false">
            <v-col cols="12" md="3">
              <v-radio-group row v-model="policeCase.isDetained" class="mt-0 ml-2">
                <v-radio label="ជាប់ឃុំ" :value="true"></v-radio>
                <v-radio label="ក្រៅឃុំ" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          -->
          <v-row>
            <v-col cols="12" md="7">
              <v-select :items="caseCrimeTypeList" v-model="criminalCharge" item-text="crimeTypeNameKh" return-object label="ជ្រើសរើសបទល្មើសនៃការចោទ" outlined hide-details multiple>
                <template v-slot:selection="{ item, index }">
                  <v-chip small>{{ item.crimeTypeNameKh }}</v-chip>
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ item.crimeTypeNameKh }}</span>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-radio-group row v-model="policeCase.isActualOffense" class="mt-0 ml-2">
                <v-radio :label="$t('case.options.actualOffense')" :value="true"></v-radio>
                <v-radio :label="$t('case.options.unrealOffense')" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9">
              <v-text-field outlined :label="$t('case.describeEvidence')" v-model="policeCase.evidenceDescription" hide-details>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btn primary" @click="register">{{$t('button.regisCase')}}</v-btn>
        <!-- $router.push('/criminal-cases/progress') -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAttachment,
  mdiPlus,
  mdiDelete,
  mdiClose,
  mdiFormatListBulleted
} from '@mdi/js'
import SystemService from '@/services/service.system'
import ServicePolice from '@/services/service.policeCase'
import Rule from '@/plugins/rules.js'
import { onBeforeRouteUpdate } from 'vue-router'

export default {
  name: 'police-cases-add',
  data() {
    return {
      icons: {
        mdiAttachment,
        mdiPlus,
        mdiDelete,
        mdiClose,
        mdiFormatListBulleted
      },
      resultMessage: '',
      rule: [],
      policeCase: {},
      partyListDialog: false,
      partyList: [],
      defendantList: [],
      caseCrimeTypeList: [],
      criminalCharge: [],
      partyRoles: []
    }
  },
  async created() {
    this.rule = Rule

    if (this.$route.params.id) {
      var caseId = this.$route.params.id

      await ServicePolice.getCaseById(caseId).then(response => {
        this.policeCase = response.data
      })

      await ServicePolice.getChargeByCaseId(caseId).then(response => {
        var chargeList = response.data
        chargeList.forEach(item => {
          this.criminalCharge.push(item.caseCrimeType)
        })
      })

      await ServicePolice.getPartyByCaseId(caseId).then(response => {
        this.partyList = response.data
      })
    }
  },
  mounted() {
    SystemService.getCaseCrimeTypeAll().then(response => {
      this.caseCrimeTypeList = response.data
    })

    ServicePolice.getCasePartyRoleAll().then(response => {
      this.partyRoles = response.data
    })
  },
  methods: {
    changedPresence() {
      if (this.policeCase.isPresence == true) {
        this.policeCase.isDetained = false
      }
    },
    register() {
      if (!this.$refs.form.validate()) return

      ServicePolice.insert(this.policeCase).then(
        response => {
          var addedCase = response.data
          if (addedCase) {
            this.resultMessage = 'Case has been registered'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            if (this.criminalCharge.length > 0) {
              var criminalChargeList = []
              this.criminalCharge.forEach((item, index) => {
                criminalChargeList.push({
                  caseCrimeType: item,
                  casePoliceRegistration: addedCase
                })
              })

              ServicePolice.insertChargeBatch(criminalChargeList).then(
                response => {
                  this.resultMessage = 'Charges have been attached'
                  this.$notify({
                    group: 'message',
                    title: 'Notification',
                    type: 'success',
                    text: this.resultMessage
                  })
                }
              )
            }

            if (this.partyList.length > 0) {
              this.partyList = this.partyList.map(v => ({
                ...v,
                caseId: addedCase.id
              }))

              ServicePolice.insertPartyBatch(this.partyList).then(response => {
                this.resultMessage = 'Parties have been attached'
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: this.resultMessage
                })
              })
            }

            this.$router.push({ name: 'police-cases' })
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    openPartyListDialog() {
      this.partyListDialog = true
    },
    addParty() {
      //  this.partyList.push({ partyRole: { id: 1 } })
      this.partyList.push({})
    },
    removeParty(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.partyList.splice(index, 1)
          }
        }
      })
    }
  }
}
</script>
<style scoped>
.right-side-dialog {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 80%;
  overflow-y: auto;
}

.slide-x-transition-enter-active,
.slide-x-transition-leave-active {
  transition: transform 0.3s ease;
}

.slide-x-transition-enter {
  transform: translateX(100%);
}

.slide-x-transition-leave-to {
  transform: translateX(100%);
}
</style>
