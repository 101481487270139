import api from './index'
import authHeader from './auth-header'

class ServicePolice {
    getCaseAll() {
        return api.get('police/case/all', { headers: authHeader() })
    }

    getPartyByCaseId(caseId) {
        return api.get('police/party/caseId/' + caseId, { headers: authHeader() })
    }
    insert(formData) {
        return api.post('police/case/create', formData, { headers: authHeader() })
    }

    insertPartyBatch(formData) {
        return api.post('police/party/createBatch', formData, { headers: authHeader() })
    }

    insertChargeBatch(formData) {
        return api.post('police/charge/createBatch', formData, { headers: authHeader() })
    }

    getChargeByCaseId(id) {
        return api.get('police/charge/caseId/' + id, { headers: authHeader() })
    }

    getCaseById(id) {
        return api.get('police/case/' + id, { headers: authHeader() })
    }

    getCasePartyRoleAll() {
        return api.get('police/party/role/all', { headers: authHeader() })
    }

    updateTransferStatus(id) {
        return api.put('police/case/updateTransfer/' + id, null, { headers: authHeader() })
    }

}

export default new ServicePolice()